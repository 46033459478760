import React, { useState, useEffect } from 'react'

import '../assets/scss/main.scss'
import Header from './Header'
import Menu from './Menu'
import Contact from './Contact'
import Footer from './Footer'


const Layout = (props) => {
    const { children } = props
    const [isMenuVisible, set_isMenuVisible] = useState(false)
    const [loading, set_loading] = useState('is-loading')

    useEffect(() => {
        setTimeout(() => {
            set_loading('');
        }, 100);
    }, [])

    function handleToggleMenu() {
        set_isMenuVisible(!isMenuVisible)
    }

    return (
        <div className={`body ${loading} ${isMenuVisible ? 'is-menu-visible' : ''}`}>
            <div id="wrapper">
                <Header onToggleMenu={() => handleToggleMenu()} />
                {children}
                <Contact />
                <Footer />
            </div>
            <Menu onToggleMenu={() => handleToggleMenu()} />
        </div>
    )
}

export default Layout
