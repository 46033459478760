import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

const Contact = () => {
    const [message, setMessage] = useState(null)


    return (
        <Section id="contact">
            <div className="inner">
                <section className="split">
                    <section style={{ borderRight: 'none' }}>
                        <div className="contact-method">
                            <span className="icon alt fa-envelope"></span>
                            <h3>Email</h3>
                            <a href="mailto:info@bmtrejuvenation.com">info@bmtrejuvenation.com</a>
                        </div>
                    </section>
                    <section>
                        <div className="contact-method">
                            <span className="icon alt fa-phone"></span>
                            <h3>Phone</h3>
                            <span>(+61) 0491 190 199</span>
                        </div>
                    </section>
                    <section>
                        <div className="contact-method">
                            <span className="icon alt fa-home"></span>
                            <h3>Address</h3>
                            <span>Gold Coast and South Brisbane</span>
                        </div>
                    </section>
                </section>
                <section>
                    {/* <form method="post" action="#">
                        <div className="field half first">
                            <label htmlFor="name">Name</label>
                            <input type="text" name="name" id="name"
                                onChange={(e) => {
                                    setMessage({ ...message, name: e.target.value })
                                }} />
                        </div>
                        <div className="field half">
                            <label htmlFor="email">Email</label>
                            <input type="text" name="email" id="email"
                                onChange={(e) => {
                                    setMessage({ ...message, email: e.target.value })
                                }} />
                        </div>
                        <div className="field">
                            <label htmlFor="message">Message</label>
                            <textarea name="message" id="message" rows="6"
                                onChange={(e) => {
                                    setMessage({ ...message, content: e.target.value })
                                }}></textarea>
                        </div>
                        <ul className="actions">
                            <li><input type="submit" value="Send Message" className="special" /></li>
                            <li><input type="reset" value="Clear" /></li>
                        </ul>
                    </form> */}
                </section>

            </div>
        </Section>
    )
}
const Section = styled.section`
    background-color: #004d4d;
`

export default Contact
