import React from 'react';
import Helmet from 'react-helmet'

const keywords = [
    'pain',
    'health',
    'nurses',
    'nursing',
    'clinics',
    'injections',
    'bmt',
    'rejuvenation',
    'joints',
    'tissue',
    'injections',
    'cosmetics',
    'arthritis',
    'sports injuries', 
    'injuries', 
    'chronic pain', 
    'hair loss'
]

export default function HelmetContent(props) {
    return (
        <Helmet
            title={props.title}
            meta={[
                { name: 'description', content: 'Bioactive Molecular Technologies and procedures.' },
                {
                    name: 'keywords',
                    content: keywords.join()
                },
            ]}
        >
        </Helmet>
    );
}